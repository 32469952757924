/*
 * @Author: slw 18071715194@189.cn
 * @Date: 2023-05-16 11:30:38
 * @LastEditors: slw 18071715194@189.cn
 * @LastEditTime: 2023-05-16 15:31:35
 * @FilePath: /WallpaperH5/src/router/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import lanhu_huaban1 from '../views/lanhu_huaban1/index.vue'

Vue.use(VueRouter)

const routes = [
    {
    path: '/',
    redirect: "/index.html"
  },
  {
    path: '/index.html',
    name: 'index',
    component: lanhu_huaban1
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
