<!--
 * @Author: slw 18071715194@189.cn
 * @Date: 2023-05-16 11:30:38
 * @LastEditors: slw 18071715194@189.cn
 * @LastEditTime: 2023-05-21 14:11:14
 * @FilePath: /WallpaperH5/src/views/lanhu_huaban1/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="page flex-col">
    <div class="box_1 flex-col">
      <div class="box_2 flex-col">
        <Slider
          class="slider_2"
          v-model="sliderValue"
          :duration="5000"
          :speed="1000"
          height = 100%
          :control-btn = false
        >
          <SliderItem
            v-for="(i, index) in list"
            :key="index"
            @click="changeIndex(1)"
          >
            <img class="image_1" :src="i">
          </SliderItem>
        </Slider>
      </div>
      <img
        class="image_2"
        referrerpolicy="no-referrer"
        src="./assets/img/psyv2o2j07pxqhuui27xljwftqvce54j55e384146-b476-4293-97ee-e5ae59831dc2.png"
      />

      <img
        class="image_3"
        referrerpolicy="no-referrer"
        src="./assets/img/psgafaseur2plcjwz3ilapvgi84t80s29mgf75410e1-42ef-451f-9000-f7514f659257.png"
      />
      <div class="box_6 flex-col">
        <button class="button_2 flex-col" @click="onClick_1">
          <img class="image_4" src="./assets/img/onClick.png" alt="" />
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import t1 from './assets/img/icon_t1.png'
import t2 from './assets/img/icon_t2.png'
import t3 from './assets/img/icon_t3.png'
import t4 from './assets/img/icon_t4.png'
import t5 from './assets/img/icon_t5.png'
export default {
  data() {
    return {
      list: [],
      sliderValue: 0,
      constants: {},
    };
  },
  methods: {
    changeIndex(index) {
      this.sliderValue = index;
    },

    onClick_1() {
      let getUrl = window.location.href
      let getqyinfo = getUrl.split('?')[1]
      let getqys = new URLSearchParams('?'+getqyinfo)  //将参数放在URLSearchParams函数中
      let getqyversion = getqys.get('version')   //1001   
      
      

      if(getqyversion == null){
        window.location.href = "https://apps.apple.com/cn/app/id1576485276";
      }else{
        window.webkit.messageHandlers.LDHJSAPI.postMessage({method:'showNewPage',body:''});
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.list = [
       t1,t2,t3,t4,t5
      ];
    }, 1000);
  },
  
};
</script>
<style scoped lang="css" src="./assets/index.response.css" />